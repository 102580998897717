import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import ImageGallery from "react-image-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ViewItemDetails = (props) => {
  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];

  const useStyles = makeStyles({
    list: {
      padding: "5px",
    },
  });
  const classes = useStyles();
  return (
    <div className="row">
      <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
        <ImageGallery
          items={props.images || images}
          thumbnailPosition="left"
          showFullscreenButton={false}
          useBrowserFullscreen={false}
          showPlayButton={false}
          showGalleryPlayButton={false}
          showNav={false}
          autoPlay={false}
          slideDuration={0}
        />
      </div>
      <div className="col-lg-6">
        <div className="icon-box">
          <h4 className="title">{props.title}</h4>

          <Typography variant="h5">Contents</Typography>
          {props.description.map((list, index) => (
            <Typography key={"itemDetails" + index} className={classes.list}>
              <FontAwesomeIcon color="#07689f" icon="check-double" /> {list}
            </Typography>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewItemDetails;
