import React from "react";
import ProductCollections from "../../components/ProductCollections";
const TabContent = (props) => {
  return (
    <>
      <h3>{props.title}</h3>
      <ProductCollections {...props} />
    </>
  );
};
export default TabContent;
