import React from "react";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./pages/Footer";
import Home from "./pages/Home";
import NavBar from "./pages/Navbar";
import Products from "./pages/Products";
import TopBar from "./pages/TopBar";
import { HashRouter, Route, Link } from "react-router-dom";
import Liposuctions from "./pages/products/Liposuctions";
import Accessories from "./pages/products/Accessories";
import MedicalEquipments from "./pages/products/MedicalEquipments";
import ScientificEquipments from "./pages/products/ScientificEquipments";
import MedicalFurnitures from "./pages/products/MedicalFurnitures";
import PostOperativeGarments from "./pages/products/PostOperative";
import ScrollToTop from "./components/ScrollToTop";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faTimes,
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  fab,
  faBars,
  faTimes,
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
  faCheckDouble
);
const App = () => {
  return (
    <HashRouter>
      <TopBar />
      <NavBar />
      <ScrollToTop />
      <Route path="/about" component={About} />
      <Route path="/products" component={Products} />
      <Route path="/contact" component={Contact} />
      <Route path="/liposuction" component={Liposuctions} />
      <Route path="/accessories" component={Accessories} />
      <Route path="/medicalequipments" component={MedicalEquipments} />
      <Route path="/scientificequipments" component={ScientificEquipments} />
      <Route path="/medicalfurnitures" component={MedicalFurnitures} />
      <Route path="/postoperative" component={PostOperativeGarments} />
      <Route exact path="/" component={Home} />
      <Footer />

      {/* <div id="preloader"></div> */}
      <Link to="#" className="back-to-top">
        <i className="icofont-simple-up"></i>
      </Link>
    </HashRouter>
  );
};
export default App;
