import React, { useState } from "react";

import DialogBox from "./DialogBox";

const ProductCollections = (props) => {
  const [open, setOpen] = useState(false);
  const [productDetails, setProductDetails] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const viewDetailsHandler = (details) => {
    setProductDetails(details);
    setOpen(true);
  };
  return (
    <div className="departments">
      <div className="tab-pane ">
        <div className="container">
          <div className="row">
            {props.list.map((product, index) => (
              <div
                key={index}
                className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
                onClick={() => viewDetailsHandler(product)}
              >
                <div className="icon-box">
                  <div className="icon">
                    <img
                      src={product.images[0].thumbnail}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <h4 className="title">{product.title}</h4>
                </div>
              </div>
            ))}
          </div>
          {productDetails && (
            <DialogBox
              open={open}
              handleClose={handleClose}
              productDetails={productDetails}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ProductCollections;
