import React from "react";
import Container from "../../components/Container";
import { postOperativeList } from "../../components/json/postOperativeGarments";
import ProductCollections from "../../components/ProductCollections";

const PostOperativeGarments = () => {
  return (
    <Container>
      <div className="section-title">
        <h2>Post Operative Garments</h2>
        <p>
          Post surgery - major or minor, it is very essential for the patient to
          be in a clean environment. So what about the garments? Even they need
          to be sterilized and cleaned. Altius knows the best suitable garments
          for this purpose because we value life. We supply tailor-made clothes
          suiting your needs.
        </p>
      </div>
      <ProductCollections list={postOperativeList} />
    </Container>
  );
};
export default PostOperativeGarments;
