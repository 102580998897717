import { liposuctionList } from "./liposuctionList";
import { accessoryList } from "./accessories/accessoriesList";
import { medicalEquipmentLists } from "./medicalEquipments/medicalEquipmentList";
import { scientificEquipmentLists } from "./scientificEquipments/scientificEquipmentsList";
import { medicalFurnituresList } from "./medicalFurnitures/medicalFurnitures";
import { postOperativeList } from "./postOperativeGarments";

export const mainProductsList = [
  {
    key: "liposuction",
    title: "Liposuctions",
    icon: "icofont-injection-syringe",
    path: "/liposuction",
    description:
      "Liposuction is a surgical procedure which involves the use of high precision instruments. Altius meticulously chooses  and brings them to you.",
    list: liposuctionList,
  },
  {
    key: "accessories",
    title: "Accessory",
    icon: "icofont-operation-theater",
    path: "/accessories",

    description:
      "The Altius section of accessories provides you with syringes, cannulas and all the other associated items required for any medical purpose.",
    list: accessoryList,
  },
  {
    key: "medicalEquipments",
    title: "Medical Equipments",
    icon: "icofont-heartbeat",
    path: "/medicalequipments",

    description:
      "Medical equipments are essential for the diagnosis and /treatment of diseases. We provide you modern and highly sophisticated instruments.",
    list: medicalEquipmentLists,
  },
  {
    key: "scientificEquipments",
    title: "Scientific Equipments",
    icon: "icofont-refrigerator",
    path: "/scientificequipments",

    description:
      "Our main set of scientific equipments are vaccine carriers and centrifuge device. They are very essential these days. ",
    list: scientificEquipmentLists,
  },
  {
    key: "furniture",
    title: "Medical Furniture",
    icon: "icofont-stretcher",
    path: "/medicalfurnitures",

    description:
      "It is not just our homes and offices that requires furniture but even a hospital does. Altius has come up with different types of medical furniture.",
    list: medicalFurnituresList,
  },
  {
    key: "postOperative",
    title: "Post Operative Garments",
    icon: "icofont-bandage",
    path: "/postoperative",

    description:
      "Altius knows the best suitable garments used post surgery. We supply tailor-made clothes suiting your needs. ",
    list: postOperativeList,
  },
];
