const path =
  process.env.PUBLIC_URL + "./images/products/scientificEquipments/centrifuge/";

export const centrifugeList = [
  {
    title: "Combo XL Swing-Out",
    images: [
      {
        original: path + "comboXl/original.jpg",
        thumbnail: path + "comboXl/thumbnail.jpg",
      },
    ],

    description: [
      "The ComboXL centrifuge spins test tubes from 3ml to 15ml, and even 50ml",
      "Speeds up to 4, 000 rpm",
      "With microprocessor speed control",
    ],
  },

  {
    title: "E8 Centrifuge",
    images: [
      {
        original: path + "e8/original.jpg",
        thumbnail: path + "e8/thumbnail.jpg",
      },
    ],

    description: [
      "Higher speeds for faster and cleaner separations",
      "Quieter operation",
      "Auto-brake to stop the rotor in less than 30 seconds",
    ],
  },
];
