const path =
  process.env.PUBLIC_URL +
  "./images/products/medicalEquipments/examinationLights/";

export const examinationLightsList = [
  {
    title: "VISIANO 20-2",
    images: [
      {
        original: path + "original.jpg",
        thumbnail: path + "thumbnail.jpg",
      },
    ],

    description: [
      "LED technology",
      "Illuminated field 210 mm",
      "Precise colour rendering Ra >95, R9 >90",
      "Colour temperatures (3500K and 4500K) allow individual contrast viewing",
      "Illumination strength dimmable in 4 stages",
      "Highest light quality due to diamond optics",
      "Antimicrobial hygiene function on important lamp components",
      "Easy operating unit on head",
      "Maximum operating radius (head can be rotated about 360°)",
      "Excellent ergonomics due to spring-balanced aluminium arm system",
      "Minimum maintenance costs due to LED technology",
      "Slight thermal radiation in light (reduced drying of tissue)",
      "Complies with the requirements of standards EN 60601-1 and EN 60601-2-41",
    ],
  },
];
