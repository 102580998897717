import React from "react";
import { Link } from "react-router-dom";
import CONSTANTS from "../constants";
const Footer = () => (
  <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="footer-info">
              <h3>Altius</h3>
              <p>
                Business Bay <br />
                Dubai, UAE
                <br />
                <br />
                <strong>Phone:</strong> {CONSTANTS.phoneNumber}
                <br />
                <strong>Email:</strong> {CONSTANTS.email}
                <br />
              </p>
              <div className="social-links mt-3">
                <Link to="#" className="twitter">
                  <i className="bx bxl-twitter"></i>
                </Link>
                <Link to="#" className="facebook">
                  <i className="bx bxl-facebook"></i>
                </Link>
                <Link to="#" className="instagram">
                  <i className="bx bxl-instagram"></i>
                </Link>
                <Link to="#" className="google-plus">
                  <i className="bx bxl-skype"></i>
                </Link>
                <Link to="#" className="linkedin">
                  <i className="bx bxl-linkedin"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Usefull Links</h4>
            <ul>
              <li>
                <i className="bx bx-chevron-right"></i>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>
                <Link to="/about">About</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Quick Links</h4>
            <ul>
              <li>
                <i className="bx bx-chevron-right"></i>
                <Link to="/liposuction">Liposuction</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>
                <Link to="/accessories">Accessories</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>
                <Link to="/medicalequipments">Medical Equipments</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>
                <Link to="/scientificequipments">Scientific Equipments</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>
                <Link to="/medicalfurnitures">Medical Furniture</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>
                <Link to="/postoperative">Post Operative Garments</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">
        &copy; Copyright{" "}
        <strong>
          <span>Altius</span>
        </strong>
        . All Rights Reserved
      </div>
    </div>
  </footer>
);
export default Footer;
