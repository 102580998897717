import React, { useState } from "react";
import Container from "../../components/Container";
import { scientificEquipmentLists } from "../../components/json/scientificEquipments/scientificEquipmentsList";
import ProductSubCategories from "./ProductSubCategories";

import TabContent from "./TabContent";

const ScientificEquipments = () => {
  const [productDetails, setProductDetails] = useState(
    scientificEquipmentLists[0]
  );

  const setProductDetailsHandler = (details) => setProductDetails(details);
  return (
    <Container>
      <div className="section-title">
        <h2>Scientific Equipments</h2>
        <p>
          Our main set of scientific equipments are vaccine carriers and
          centrifuge device. They are very essential these days. Safety is our
          first priority and we just ensure it. These are very easy to handle
          and can be used for various purposes.
        </p>
      </div>
      <ProductSubCategories
        list={scientificEquipmentLists}
        setProductDetailsHandler={setProductDetailsHandler}
      />
      {productDetails && <TabContent {...productDetails} />}
    </Container>
  );
};
export default ScientificEquipments;
