import React from "react";

const Container = (props) => {
  return (
    <section id="products" className="services">
      <div className="container" data-aos="fade-up">
        {props.children}
      </div>
    </section>
  );
};
export default Container;
