const path =
  process.env.PUBLIC_URL + "./images/products/accessories/pressureCuffs/";

export const pressureCuffsList = [
  {
    title: "Infusion Pressure Cuff (500ml/1000ml)",
    images: [
      {
        original: path + "infussion/original.jpg",
        thumbnail: path + "infussion/thumbnail.jpg",
      },
    ],

    description: [
      "Pressure infusor. Constructed of a clear material to allow for visual inspection of fluid levels. An economic means of infusing your standard IV bags. Instead of an analog gauge, a 'pop-out' pressure stick will give you a 'ball-park' range of your infusion pressure.",
    ],
  },

  {
    title: "Infusion Pressure Cuff (500ml/1000ml) With Guage",
    images: [
      {
        original: path + "infussionWithGuage/original.jpg",
        thumbnail: path + "infussionWithGuage/thumbnail.jpg",
      },
    ],

    description: [
      "Complete unit with squeeze bulb and pressure gauge. The pressure cuff allows for precise pressure control during infusion procedures from any standard IV bag. Constructed of a clear material to allow for visual inspection of fluid levels",
    ],
  },

  {
    title: "Infusion Pressure Cuff (500ml/1000ml) With Guage",
    images: [
      {
        original: path + "extension/original.jpg",
        thumbnail: path + "extension/thumbnail.jpg",
      },
    ],

    description: [
      "Extension to 1000ml infusion pressure cuffs with gauge units for use with 3000ml fluid containers",
    ],
  },
  {
    title: "Guage",
    images: [
      {
        original: path + "guage/original.jpg",
        thumbnail: path + "guage/thumbnail.jpg",
      },
    ],

    description: ["Pressure gauge for infusion pressure cuffs"],
  },
];
