const path =
  process.env.PUBLIC_URL + "./images/products/medicalEquipments/co2Regulator/";

export const co2RegulatorList = [
  {
    title: "Carbo CO2 Regulator",
    images: [
      {
        original: path + "original.jpg",
        thumbnail: path + "thumbnail.jpg",
      },
    ],

    description: [
      "CO2 regulator included",
      "Foot switch included",
      "LCD monitor",
    ],
  },
];
