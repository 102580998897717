const path =
  process.env.PUBLIC_URL + "./images/products/medicalEquipments/lightSource/";

export const lightSourceList = [
  {
    title: "ACE® Light Source",
    images: [
      {
        original: path + "original.jpg",
        thumbnail: path + "thumbnail.jpg",
      },
    ],

    description: [
      "Solid state dimmer for continuous control zero to full intensity",
      "Heavy duty, grounded metal housing",
      "115V or 230V input voltage versions available",
      "IR interference filter for cool illumination",
      "Full illumination on .60” active diameter input ColdVision light guides",
      "Interchangeable Modulamp Units offer 2 positions to maximize individual lamp efficiencies",
      "Choice of two, 150 watt halogen lamps - EKE and DDL for intense, uniform illumination. These three different bulbs offer light intensity and color temperature options to maximize productivity and accuracy.",
      "Built-in thermal shut down",
      "Quiet fan cooling",
      "CSA approved to UL spec, CE compliant to low voltage and EMC directives",
      "Low housing temperature",
      "Detachable IEC cord",
    ],
  },
];
