import { aspiratorList } from "./categories/aspirator";
import { co2RegulatorList } from "./categories/co2Regulator";
import { electroSurgeryUnitList } from "./categories/electroSurgeryUnit";
import { examinationLightsList } from "./categories/examinationLights";
import { fatGraftingSystemList } from "./categories/fatGraftingSystem";
import { imagingSystemList } from "./categories/imagingSystems";
import { infiltrationUnitList } from "./categories/infiltration";
import { lightSourceList } from "./categories/lightSource";
import { microDermaList } from "./categories/microDerma";
import { veinViewerList } from "./categories/veinViewer";

export const medicalEquipmentLists = [
  {
    key: "aspirator",
    title: "Aspiration System",
    list: aspiratorList,
  },
  {
    key: "co2Regulator",
    title: "Co2 Regulators",
    list: co2RegulatorList,
  },
  {
    key: "microDerma",
    title: "Microdermabrasion",
    list: microDermaList,
  },
  {
    key: "electroSurgery",
    title: "Electro Surgery Unit",
    list: electroSurgeryUnitList,
  },
  {
    key: "examinationLights",
    title: "Examination Lights",
    list: examinationLightsList,
  },
  {
    key: "fatGraftingSystem",
    title: "Fat Grafting System",
    list: fatGraftingSystemList,
  },
  {
    key: "imagingSystem",
    title: "Imaging Systems",
    list: imagingSystemList,
  },

  {
    key: "infiltrationUnit",
    title: "Infiltration Unit",
    list: infiltrationUnitList,
  },
  {
    key: "lightSource",
    title: "Light Source",
    list: lightSourceList,
  },
  {
    key: "veinViewer",
    title: "Vein Viewers",
    list: veinViewerList,
  },
];
