const path =
  process.env.PUBLIC_URL +
  "./images/products/medicalEquipments/infiltrationUnit/";

export const infiltrationUnitList = [
  {
    title: "KLEIN TOUCH PUMP",
    images: [
      {
        original: path + "original.png",
        thumbnail: path + "thumbnail.png",
      },
    ],

    description: [
      "Maximum Motor Speed: 450RPM",
      "Holding Torque: 294 in-oz (2.08 N-m)",
      "Flow rate: Up to 1000 mL/min depending upon tubing set, connected cannula and pump speed",
      "Mode of operation: Toggle, Persistent, Trigger",
      "Power Requirements: 100-240 VAC, Inlet Hospital power cord IEC 320,",
      "Use only cordage terminated in hospital grade plugs to ensure power grounding.",
      "AC Frequency- 50 to 60 HZ single phase.",
      "Mains Power Input Fusing: 2.5A, 250V",
      "Leakage Current within IEC 60601-1",
      "Operating Temperature: 4°C to 40°C",
      "Storage & Shipping Temperature: -20 to 50°C",
      "Protection against electric shock: Class I, Type B",
      "Enclosure rating: Ordinary equipment",
      "Display: Color Graphic LCD",
      "Dual Foot switches with 12 ft hose, air activated",
      "Dimensions: 11” x 10 ¾” x 5”",
      "Weight: 8.4 lbs",
      "Liquid ingress rating: IPO – No protection",
    ],
  },
];
