const path = process.env.PUBLIC_URL + "./images/products/medicalFurnitures/";

export const medicalFurnituresList = [
  {
    title: "Medical Bed",
    images: [
      {
        original: path + "medicalBed/original.jpg",
        thumbnail: path + "medicalBed/thumbnail.jpg",
      },
    ],

    description: [
      "Electrical equipment with degree of protection: IP 66",
      "Minimum height = 40 cm * Maximum height = 91 cm",
      "Height system variable treated with ANTHRACITE GRAY epoxy powders",
      "Bedding 200 x 90 cm (European standard), Compact HPL, equipped with",
      "2 devices for bracket, at an angle on the bed base",
      "2 devices for IV pole",
      "Electric backrest",
      "Translation of the backrest",
      "Electric bending  of the knees with legrest",
      "Nursing position for care - raising and flattening the bed base",
      "Ground clearance: 15.5 cm",
    ],
  },
  {
    title: "Comfort Chairs",
    images: [
      {
        original: path + "chair/original.jpg",
        thumbnail: path + "chair/thumbnail.jpg",
      },
    ],

    description: ["Reclining chairs"],
  },
  {
    title: "Stretcher",
    images: [
      {
        original: path + "stretcher/original.jpg",
        thumbnail: path + "stretcher/thumbnail.jpg",
      },
    ],

    description: [
      "Variable height from 57 to 90cm (without mattress)",
      "Trendelenburg / Reverse Trendelenburg controls on both sides",
      "Polyester epoxy-coated steel structure, cloud grey",
      "X-ray translucent resin mattress base, C-arm compatible",
      "Four 200mm castors with centralised braking, two steering castors",
      "at the head end (controls on all four corners)",
      "Dual gas actuator adjustable backrest",
      "Four corner bumpers and protective bumpers along the sides",
      "Four accessory sockets",
      "The Cotsides fold away neatly beside the mattress",
      "Push handles",
      "Patient weight capacity: 250kg",
      "Various mattress options available to suit specific requirements",
    ],
  },
  {
    title: "Medication Cart",
    images: [
      {
        original: path + "medicationCart/original.png",
        thumbnail: path + "medicationCart/thumbnail.png",
      },
    ],

    description: [
      "Drawers",
      "ABS basket tray 50",
      "7 daily pillboxes tray set medium model",
      "Shelf",
      "Cassette",
    ],
  },
  {
    title: "Emergency Cart",
    images: [
      {
        original: path + "emergencyCart/original.png",
        thumbnail: path + "emergencyCart/thumbnail.png",
      },
    ],

    description: ["Drawers"],
  },
  {
    title: "Small Mobile Cabinet",
    images: [
      {
        original: path + "smallCabinet/original.png",
        thumbnail: path + "smallCabinet/thumbnail.png",
      },
    ],

    description: [
      "Drawers",
      "ABS basket tray 50",
      "7 daily pillboxes tray set medium model",
      "Shelf",
      "Cassette",
    ],
  },
  {
    title: "Anaesthesia cart",
    images: [
      {
        original: path + "anaesthesiaCart/original.png",
        thumbnail: path + "anaesthesiaCart/thumbnail.png",
      },
    ],

    description: [
      "Drawers",
      "ABS basket tray 50",
      "7 daily pillboxes tray set medium model",
      "Shelf",
    ],
  },

  {
    title: "Transfer Chair",
    images: [
      {
        original: path + "transferChair/original.jpg",
        thumbnail: path + "transferChair/thumbnail.jpg",
      },
    ],

    description: [
      "MAXIMUM LOAD = 135 kg",
      "Metal structure in steel tube",
      "VOLCANIC BLACK epoxy paint finish",
      "4 wheels diameter 125 mm",
      "Seat and backrest in polyurethane foam",
      "Adjustable armrests in height and retractable to facilitate the transfer",
      "Bilateral pedals for braking the wheelchair",
    ],
  },
];
