const path = process.env.PUBLIC_URL + "./images/products/accessories/syringes/";

export const syringeList = [
  {
    title: "Syringes-BD",
    images: [
      {
        original: path + "60bd/original.jpg",
        thumbnail: path + "60bd/thumbnail.jpg",
      },
    ],

    description: [
      "BD 60cc Toomey Syringe with luer lock tip.",
      "Sterile Single Use",
    ],
  },
  {
    title: "Syringes-Monoject",
    images: [
      {
        original: path + "monoject/original.jpg",
        thumbnail: path + "monoject/thumbnail.jpg",
      },
    ],

    description: ["Monoject Syringe with luer lock tip.", "Sterile Single Use"],
  },
  {
    title: "Syringe Caps",
    images: [
      {
        original: path + "syringeCaps/original.jpg",
        thumbnail: path + "syringeCaps/thumbnail.jpg",
      },
    ],

    description: ["Microinjection Syringe Caps - Disposable"],
  },
];
