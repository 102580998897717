const path =
  process.env.PUBLIC_URL + "./images/products/accessories/disposables/";

export const disposablesList = [
  {
    title: "Baxter Cannister",
    images: [
      {
        original: path + "BaxterCanister/original.jpg",
        thumbnail: path + "BaxterCanister/thumbnail.jpg",
      },
    ],

    description: ["Reusable 1500cc Hard Canister"],
  },

  {
    title: "Baxter Liners",
    images: [
      {
        original: path + "BaxterLiner/original.jpg",
        thumbnail: path + "BaxterLiner/thumbnail.jpg",
      },
    ],

    description: ["1500cc Disposable Liners"],
  },

  {
    title: "Sorenson Canister",
    images: [
      {
        original: path + "SorensonCanister/original.jpg",
        thumbnail: path + "SorensonCanister/thumbnail.jpg",
      },
    ],

    description: ["1900cc reusable canisters"],
  },

  {
    title: "Sorenson Liners",
    images: [
      {
        original: path + "SorensonLiner/original.jpg",
        thumbnail: path + "SorensonLiner/thumbnail.jpg",
      },
    ],

    description: ["1900cc Disposable Liners"],
  },

  {
    title: "Quick-Fit Canister",
    images: [
      {
        original: path + "QuickFitCanister/original.jpg",
        thumbnail: path + "QuickFitCanister/thumbnail.jpg",
      },
    ],

    description: ["3000cc reusable canister"],
  },

  {
    title: "Quick-Fit Liner",
    images: [
      {
        original: path + "QuickFitLiner/original.jpg",
        thumbnail: path + "QuickFitLiner/thumbnail.jpg",
      },
    ],

    description: ["3000cc dispossable liner"],
  },

  {
    title: "Baxter™ Canister Holder 1500cc",
    images: [
      {
        original: path + "BaxterCanisterHolder/original.jpg",
        thumbnail: path + "BaxterCanisterHolder/thumbnail.jpg",
      },
    ],

    description: [
      "Fits on top panel of the aspirator to hold 1500cc Baxter™ Canisters in place.",
    ],
  },
  {
    title: "Sorenson™ Canister Holder 1900cc",
    images: [
      {
        original: path + "SorensonCanisterHolder/original.jpg",
        thumbnail: path + "SorensonCanisterHolder/thumbnail.jpg",
      },
    ],

    description: [
      "Fits on top panel of the aspirator to hold 1900cc Sorenson™ Canisters in place.",
    ],
  },

  {
    title: "Quick-Fit™ Canister Holder 3000cc",
    images: [
      {
        original: path + "QuickFitCanisterHolder/original.jpg",
        thumbnail: path + "QuickFitCanisterHolder/thumbnail.jpg",
      },
    ],

    description: [
      "Fits on top panel of the aspirator to hold 3000cc Quick-Fit™ Canisters in place.",
    ],
  },

  {
    title: "Standard Ultra-Limp",
    images: [
      {
        original: path + "StandardUltraLimp/original.jpg",
        thumbnail: path + "StandardUltraLimp/thumbnail.jpg",
      },
    ],
    description: [
      "9′ Long for use with Baxter canister system (Case of 10)",
      "Will withstand 29 1/2″ of vacuum (will not collapse).",
    ],
  },

  {
    title: "MB Ultra-Limp",
    images: [
      {
        original: path + "MbUltraLimp/original.jpg",
        thumbnail: path + "MbUltraLimp/thumbnail.jpg",
      },
    ],
    description: [
      "9′ Long for use with Sorenson canister system",
      "Will withstand 29 1/2″ of vacuum (will not collapse).",
    ],
  },

  {
    title: "Y-Infiltration Tubing",
    images: [
      {
        original: path + "YInfiltration/original.jpg",
        thumbnail: path + "YInfiltration/thumbnail.jpg",
      },
    ],
    description: [
      "With silicone sleeve, 170” length, dual bag spikes for non-stop infiltration.",
    ],
  },

  {
    title: "Large Bore Infiltration Tubing",
    images: [
      {
        original: path + "LargeBoreInfiltration/original.jpg",
        thumbnail: path + "LargeBoreInfiltration/thumbnail.jpg",
      },
    ],
    description: [
      "Large bore for more rapid infiltration and for increased flow.",
      "With silicone sleeve, 188” length.",
      "Single spike. Works with infusion pump.",
    ],
  },

  {
    title: "Y Large Bore Infiltration Tubing",
    images: [
      {
        original: path + "YLargeBoreInfiltration/original.jpg",
        thumbnail: path + "YLargeBoreInfiltration/thumbnail.jpg",
      },
    ],
    description: [
      "Larger diameter for more rapid infiltration. ",
      "Dual bag spikes for non-stop infiltration",
      "Includes silicone sleeve, 170” length.",
    ],
  },

  {
    title: "C Bore Infiltration Tubing",
    images: [
      {
        original: path + "CBoreInfiltration/original.jpg",
        thumbnail: path + "CBoreInfiltration/thumbnail.jpg",
      },
    ],
    description: [
      "Single spike",
      "Works with infiltration pump",
      "With silicone sleeve. 156” length",
    ],
  },

  {
    title: "High Flow Bacterial Filter",
    images: [
      {
        original: path + "HighFlowBacterialFilter/original.jpg",
        thumbnail: path + "HighFlowBacterialFilter/thumbnail.jpg",
      },
    ],
    description: [".2 micron", "maximum use 24 hours"],
  },

  {
    title: "Overflow Trap Assembly",
    images: [
      {
        original: path + "OverflowTrapAssembly/original.jpg",
        thumbnail: path + "OverflowTrapAssembly/thumbnail.jpg",
      },
    ],
    description: ["Jar", "lid", "overflow ball."],
  },

  {
    title: "Muffler",
    images: [
      {
        original: path + "Muffler/original.jpg",
        thumbnail: path + "Muffler/thumbnail.jpg",
      },
    ],
    description: [],
  },

  {
    title: "Muffler, Asp II and II+ Models",
    images: [
      {
        original: path + "MufflerAsp/original.jpg",
        thumbnail: path + "MufflerAsp/thumbnail.jpg",
      },
    ],
    description: [],
  },

  {
    title: "In-Line Collection Traps 40cc",
    images: [
      {
        original: path + "InlineCollectionTrap40/original.jpg",
        thumbnail: path + "InlineCollectionTrap40/thumbnail.jpg",
      },
    ],
    description: ["Single packed", "disposable"],
  },
  {
    title: "In-Line Collection Traps 70cc",
    images: [
      {
        original: path + "InlineCollectionTrap70/original.jpg",
        thumbnail: path + "InlineCollectionTrap70/thumbnail.jpg",
      },
    ],
    description: ["Single packed", "disposable"],
  },

  {
    title: "Cleaning Stylets",
    images: [
      {
        original: path + "CleaningStylets/original.jpg",
        thumbnail: path + "CleaningStylets/thumbnail.jpg",
      },
    ],
    description: [
      "Micro Cannula Cleaning Stylets in select sizes 11g, 12g, 14g, 16g, 17g, 18g and 20g",
    ],
  },

  {
    title: "Sorenson Canister Cart",
    images: [
      {
        original: path + "SorensonCanisterCart/original.jpg",
        thumbnail: path + "SorensonCanisterCart/thumbnail.jpg",
      },
    ],
    description: [
      "Conveniently holds two 1900cc canisters",
      "Easy to measure symmetry",
    ],
  },

  {
    title: "Baxter Canister Cart",
    images: [
      {
        original: path + "BaxterCanisterCart/original.jpg",
        thumbnail: path + "BaxterCanisterCart/thumbnail.jpg",
      },
    ],
    description: [
      "Developed for larger aspiration procedures",
      "Conveniently holds four 1500cc canisters",
      "Easy to measure symmetry",
      "Holds up to 6000cc",
    ],
  },

  {
    title: "Brush Set of 12",
    images: [
      {
        original: path + "Brush/original.jpg",
        thumbnail: path + "Brush/thumbnail.jpg",
      },
    ],
    description: [
      "Micro Brush, 1.5mm – 3mm 20-5232-00 (Package of 4)",
      "Small Brush, 4mm – 6mm 20-5230-00 (Package of 4)",
      "Large Brush, 7mm – 10mm 20-5231-00 (Package of 4)",
    ],
  },

  {
    title: "Dome Bag",
    images: [
      {
        original: path + "DomeBag/original.jpg",
        thumbnail: path + "DomeBag/thumbnail.jpg",
      },
    ],
    description: ["Case of 10"],
  },

  {
    title: "Large Brush, 7mm – 10mm",
    images: [
      {
        original: path + "LargeBrush/original.jpg",
        thumbnail: path + "LargeBrush/thumbnail.jpg",
      },
    ],
    description: [],
  },

  {
    title: "Micro Brush 1.5mm – 3mm",
    images: [
      {
        original: path + "MicroBrush/original.jpg",
        thumbnail: path + "MicroBrush/thumbnail.jpg",
      },
    ],
    description: [],
  },

  {
    title: "Small Brush 4mm – 6mm",
    images: [
      {
        original: path + "SmallBrush/original.jpg",
        thumbnail: path + "SmallBrush/thumbnail.jpg",
      },
    ],
    description: [],
  },

  {
    title: "Percutaneous Infiltrator",
    images: [
      {
        original: path + "PercutaneousInfiltrator/original.jpg",
        thumbnail: path + "PercutaneousInfiltrator/thumbnail.jpg",
      },
    ],
    description: ["Packaged individually, sterile and disposable"],
  },

  {
    title: "Quick-Fit™ Canister Cart",
    images: [
      {
        original: path + "QuickFitCanisterCart/original.jpg",
        thumbnail: path + "QuickFitCanisterCart/thumbnail.jpg",
      },
    ],
    description: ["Holds four 3000cc canisters", " Holds up to 12,000cc’s"],
  },

  {
    title: "4” Face Vacuum Gauge",
    images: [
      {
        original: path + "FaceVacuum/original.jpg",
        thumbnail: path + "FaceVacuum/thumbnail.jpg",
      },
    ],
    description: [],
  },
];
