import React from "react";
import CONSTANTS from "../constants";
const TopBar = () => (
  <div id="topbar" className="d-none d-lg-flex align-items-center fixed-top">
    <div className="container d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <i className="icofont-clock-time"></i> Sunday - Thursday, 8AM to 5PM
      </div>
      <div className="d-flex align-items-center">
        <i className="icofont-phone"></i> Call us now {CONSTANTS.phoneNumber}
      </div>
    </div>
  </div>
);
export default TopBar;
