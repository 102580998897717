const path =
  process.env.PUBLIC_URL +
  "./images/products/medicalEquipments/electroSurgeryUnit/";

export const electroSurgeryUnitList = [
  {
    title: "Spectrum",
    images: [
      {
        original: path + "original.jpg",
        thumbnail: path + "thumbnail.jpg",
      },
    ],

    description: [
      "10″ high-resolution color screen",
      "Up to 100 memory locations",
      "New operation modes",
      "Automatic power regulation",
      "Real-time monitoring of output",
      "parameters",
      "Power monitor",
      "Different language version",
      "Voice communication",
    ],
  },
];
