const path =
  process.env.PUBLIC_URL + "./images/products/medicalEquipments/veinViewer/";

export const veinViewerList = [
  {
    title: "VPism-C",
    images: [
      {
        original: path + "original.jpg",
        thumbnail: path + "thumbnail.jpg",
      },
    ],

    description: [
      "Probe Target",
      "Probe Method",
      "Probe Depth",
      "Magnification Function",
      "Automatic Adjustment Function",
      "Specialized Light Function",
      "Operation Method",
      "Internal Battery Support",
    ],
  },
];
