import React from "react";
import Container from "../../components/Container";
import { liposuctionList } from "../../components/json/liposuctionList";
import ProductCollections from "../../components/ProductCollections";

const Liposuctions = () => {
  return (
    <Container>
      <div className="section-title">
        <h2>Liposuction</h2>
        <p>
          Liposuction is a surgical procedure which employs the removal of
          removal of excess fats from the body using high precision instruments.
          It is also known as body contouring. Altius meticulously chooses the
          instruments and bring them to you because we care for you.
        </p>
      </div>
      <ProductCollections list={liposuctionList} />
    </Container>
  );
};
export default Liposuctions;
