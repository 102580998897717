import React from "react";
import { Link } from "react-router-dom";
import { mainProductsList } from "./json/MainProductsList";

const MainProducts = () => {
  return (
    <div className="row">
      {mainProductsList.map((product) => (
        <div key={product.title} className="col-lg-4 col-md-6 icon-box">
          <Link to={product.path}>
            <div className="icon">
              <i className={product.icon}></i>
            </div>
            <h4 className="title">{product.title}</h4>
            <p className="description">{product.description}</p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default MainProducts;
