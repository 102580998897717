import React from "react";
import MainProducts from "../components/MainProducts";
const Products = () => {
  return (
    <section id="products" className="services">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Products</h2>
          <p>
            Altius provides you with diverse and superior quality equipments as
            well as other essential components for a medical team/ hospital. Our
            main sections are liposuction, accessories, medical equipments,
            scientific equipments, medical furniture and post operative
            garments. These are not made from just any modest materials but from
            the superior quality materials available.
          </p>
        </div>
        <MainProducts />
      </div>
    </section>
  );
};
export default Products;
