import React from "react";
import ContactForm from "../components/ContactForm";
import CONSTANTS from "../constants";
const Contact = () => (
  <section id="contact" className="contact">
    <div className="container">
      <div className="section-title">
        <h2>Contact</h2>
        <p>
          We are very sure that your are impressed with the products and
          facilities we offer. Further if you have any queries do connect to us.
          Here are our helpline numbers and contact form.
        </p>
      </div>
    </div>
    <div className="container">
      <div className="row mt-5">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-md-12">
              <div className="info-box">
                <i className="bx bx-map"></i>
                <h3>Our Address</h3>
                <p> Business Bay, Dubai UAE</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="info-box mt-4">
                <i className="bx bx-envelope"></i>
                <h3>Email Us</h3>
                <p>{CONSTANTS.email}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="info-box mt-4">
                <i className="bx bx-phone-call"></i>
                <h3>Call Us</h3>
                <p>{CONSTANTS.phoneNumber}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <ContactForm />
        </div>
      </div>
    </div>
  </section>
);

export default Contact;
