const path =
  process.env.PUBLIC_URL +
  "./images/products/scientificEquipments/refrigerator/";

export const refrigeratorList = [
  {
    title: "Vaccine Carrier",
    images: [
      {
        original: path + "original.jpg",
        thumbnail: path + "thumbnail.jpg",
      },
    ],

    description: [
      "Temp range:+2° to +10°C",
      "Supplied with mains and in car power cables",
      "Safe gas free peltier refrigeration",
      "Lightweight and easily transportable",
      "Digital temperature display",
      "Easy digital temperature adjustment",
      "Tough plastic exterior",
      "Insulation pad",
    ],
  },
];
