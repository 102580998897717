import React from "react";
import { Box, Chip } from "@material-ui/core";

const ProductSubCategories = (props) => {
  return (
    <Box textAlign="center">
      {props.list.map((item) => (
        <Chip
          style={{ margin: "5px", background: "#07689f" }}
          clickable
          onClick={() => props.setProductDetailsHandler(item)}
          color="primary"
          key={item.key}
          label={item.title}
        />
      ))}
    </Box>
  );
};

export default ProductSubCategories;
