const path = process.env.PUBLIC_URL + "/images/products/postOperativeGarments/";

export const postOperativeList = [
  {
    title: "Abdominal Binders",
    images: [
      {
        original: path + "abdominalBinders/original.jpg",
        thumbnail: path + "abdominalBinders/thumbnail.jpg",
      },
    ],

    description: ['One Size: 28-44" (71-112 cm)'],
  },
  {
    title: "Arm Garments",
    images: [
      {
        original: path + "armGarments/original.jpg",
        thumbnail: path + "armGarments/thumbnail.jpg",
      },
    ],

    description: ["XXX-Large: 20-22 in shoulder, 14-15 in bicep"],
  },
  {
    title: "Compression Bra",
    images: [
      {
        original: path + "breastAugmentation/original.jpg",
        thumbnail: path + "breastAugmentation/thumbnail.jpg",
      },
    ],

    description: ["Fits B-C-D cups", "Available in white and beige"],
  },

  {
    title: "Breast Augmentation Band",
    images: [
      {
        original: path + "breastWrap/original.jpg",
        thumbnail: path + "breastWrap/thumbnail.jpg",
      },
    ],

    description: ['One Size; up to 45" (114cm)'],
  },
  {
    title: "Male Compression Vest",
    images: [
      {
        original: path + "compressionVest/original.jpg",
        thumbnail: path + "compressionVest/thumbnail.jpg",
      },
    ],

    description: [
      "Fully adjustable contact closure to help insure proper fit and ease of application",
    ],
  },
  {
    title: "Universal Facial Wrap",
    images: [
      {
        original: path + "facialGarment/original.jpg",
        thumbnail: path + "facialGarment/thumbnail.jpg",
      },
    ],

    description: ["Size: One Size"],
  },

  {
    title: "Compression Gridle",
    images: [
      {
        original: path + "liposuctionGarment/original.jpg",
        thumbnail: path + "liposuctionGarment/thumbnail.jpg",
      },
    ],

    description: [
      "Excellent for surgeries around the buttocks, flanks and thighs.",
    ],
  },

  {
    title: "Metcalf Post Surgical Garment",
    images: [
      {
        original: path + "metaclafGarment/original.png",
        thumbnail: path + "metaclafGarment/thumbnail.jpg",
      },
    ],

    description: ["Size: One Size"],
  },
  {
    title: "High Waist Compression",
    images: [
      {
        original: path + "2StageGarment/original.jpg",
        thumbnail: path + "2StageGarment/thumbnail.jpg",
      },
    ],

    description: [
      "Excellent for surgeries around the buttocks,flanks, thighs and lower abdomen",
    ],
  },

  {
    title: "THerapy Wrap",
    images: [
      {
        original: path + "therapyWrap/original.jpg",
        thumbnail: path + "therapyWrap/thumbnail.jpg",
      },
    ],

    description: [
      "These wraps are designed to help relieve the pain and swelling in and around the jaw.",
    ],
  },
];
