import React from "react";
import Container from "../../components/Container";
import { medicalFurnituresList } from "../../components/json/medicalFurnitures/medicalFurnitures";
import ProductCollections from "../../components/ProductCollections";

const MedicalFurnitures = () => {
  return (
    <Container>
      <div className="section-title">
        <h2>Medical Furnitires</h2>
        <p>
          It is not just our homes and offices that requires furniture but even
          a hospital does. It would be really good if we could control the
          position or height of the stretcher. Isn’t it? Imagination is now a
          reality as Altius has come up with different types of medical
          furniture to suit our requirements.
        </p>
      </div>

      <ProductCollections list={medicalFurnituresList} />
    </Container>
  );
};
export default MedicalFurnitures;
