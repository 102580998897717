const path =
  process.env.PUBLIC_URL +
  "./images/products/medicalEquipments/fatGraftingSystem/";

export const fatGraftingSystemList = [
  {
    title: "TYPE 3 -2000CC CANISTER PACKAGE",
    images: [
      {
        original: path + "original.jpg",
        thumbnail: path + "thumbnail.jpg",
      },
    ],

    description: [
      "One (1) Liposales Type-3 2000cc Canister",
      "One (1) custom stand with stainless steel detachable legs",
      "One (1) Large lid with overflow cone",
      "One (1) Medal lid coupler",
      "One (1) toomey connection drain tube",
      "One (1) luer/barbed combination drain tube",
    ],
  },
];
