const path =
  process.env.PUBLIC_URL + "./images/products/accessories/syringeRacks/";

export const syringeRackList = [
  {
    title: "S1 Syringe Rack",
    images: [
      {
        original: path + "s1/original.jpg",
        thumbnail: path + "s1/thumbnail.jpg",
      },
    ],

    description: ["S-Rack Syringe Rack, holds 12 syringes from 1cc to 10cc"],
  },

  {
    title: "S2 Syringe Rack",
    images: [
      {
        original: path + "s2/original.jpg",
        thumbnail: path + "s2/thumbnail.jpg",
      },
    ],

    description: [
      "S-Rack Syringe Rack, holds 9 syringes up to 60cc. NOTE: 6 slots will fit 60cc syringes",
    ],
  },
  {
    title: "S3 Syringe Rack",
    images: [
      {
        original: path + "s3/original.jpg",
        thumbnail: path + "s3/thumbnail.jpg",
      },
    ],

    description: ["S-Rack Syringe Rack, holds 16 syringes up to 60cc"],
  },

  {
    title: "S4 Syringe Rack",
    images: [
      {
        original: path + "s4/original.jpg",
        thumbnail: path + "s4/thumbnail.jpg",
      },
    ],

    description: [
      "S-Rack Syringe Rack, holds 28 syringes from 1cc up to 60cc. NOTE: 8 slots will fit 60cc syringes",
    ],
  },
  {
    title: "Autoclavable Syringe Rack",
    images: [
      {
        original: path + "autoClavable/original.jpg",
        thumbnail: path + "autoClavable/thumbnail.jpg",
      },
    ],

    description: [
      "Medical Grade plastic. Will withstand sterilization temperatures. Lightweight and stackable. The ideal device to aid in transfers and reinjection",
    ],
  },
];
