import React from "react";
import { Link } from "react-router-dom";
const Home = () => (
  <section id="home">
    <div
      id="homeCarousel"
      className="carousel slide carousel-fade"
      data-ride="carousel"
    >
      <ol className="carousel-indicators" id="home-carousel-indicators"></ol>

      <div className="carousel-inner" role="listbox">
        <div
          className="carousel-item active"
          style={{ background: "url(assets/img/slide/slide-1.jpg)" }}
        >
          <div className="container">
            <h2>
              Welcome to <span>Altius</span>
            </h2>
            <p>
              Altius is a new destination for all your medical equipment
              requirements. We introduce to you a new range of products enabling
              you to find the best choice. We forsee a world with a change in
              medical equipments because our specialists pickup the expertise
              instruments as per your demand.
            </p>
            <Link to="/about" className="btn-get-started scrollto">
              Read More
            </Link>
          </div>
        </div>

        <div
          className="carousel-item"
          style={{ background: "url(assets/img/slide/slide-2.jpg)" }}
        >
          <div className="container">
            <h2>
              Welcome to <span>Altius</span>
            </h2>
            <p>
              Altius is a new destination for all your medical equipment
              requirements. We introduce to you a new range of products enabling
              you to find the best choice. We forsee a world with a change in
              medical equipments because our specialists pickup the expertise
              instruments as per your demand.
            </p>
            <Link to="/about" className="btn-get-started scrollto">
              Read More
            </Link>
          </div>
        </div>

        <div
          className="carousel-item"
          style={{ background: "url(assets/img/slide/slide-3.jpg)" }}
        >
          <div className="container">
            <h2>
              Welcome to <span>Altius</span>
            </h2>
            <p>
              Altius is a new destination for all your medical equipment
              requirements. We introduce to you a new range of products enabling
              you to find the best choice. We forsee a world with a change in
              medical equipments because our specialists pickup the expertise
              instruments as per your demand.
            </p>
            <Link to="/about" className="btn-get-started scrollto">
              Read More
            </Link>
          </div>
        </div>
      </div>

      <a
        className="carousel-control-prev"
        href="#homeCarousel"
        role="button"
        data-slide="prev"
      >
        <span
          className="carousel-control-prev-icon icofont-simple-left"
          aria-hidden="true"
        ></span>
        <span className="sr-only">Previous</span>
      </a>

      <a
        className="carousel-control-next"
        href="#homeCarousel"
        role="button"
        data-slide="next"
      >
        <span
          className="carousel-control-next-icon icofont-simple-right"
          aria-hidden="true"
        ></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  </section>
);
export default Home;
