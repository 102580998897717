const path = process.env.PUBLIC_URL + "/images/products/liposuction/";

export const liposuctionList = [
  {
    title: "Standard 16g Micro Cannula Set",
    images: [
      {
        original: path + "16g-standard-cannula.jpg",
        thumbnail: path + "16g-standard-cannula.jpg",
      },
      {
        original: path + "16g-standard-cannula.jpg",
        thumbnail: path + "16g-standard-cannula.jpg",
      },
      {
        original: path + "16g-standard-cannula.jpg",
        thumbnail: path + "16g-standard-cannula.jpg",
      },
      {
        original: path + "16g-standard-cannula.jpg",
        thumbnail: path + "16g-standard-cannula.jpg",
      },
      {
        original: path + "16g-standard-cannula.jpg",
        thumbnail: path + "16g-standard-cannula.jpg",
      },
    ],

    description: [
      "16 gauge x 16 cm Multi-Hole Infiltration - 1 nos",
      "12 gauge x 15 cm Aspiration - 1 nos",
      "16 gauge x 9 cm Side Port Re-Injection - 1 nos",
      "16 gauge x 9 cm Convex Side Port Re-Injection - 1 nos",
      "16 gauge x 9 cm Open Ended Re-Injection - 1 nos",
      "16 gauge x 9 cm Convex Open Ended Re-Injection - 1 nos",
      "16 gauge x 9 cm V-Dissection Re-Injection - 1 nos",
      "Cleaning Stylets - 4 nos",
      "Female to Female Transfer - 1 nos",
    ],
  },
  {
    title: "Standard 18g Micro Cannula Set",
    images: [
      {
        original: path + "18g-standard-cannula.jpg",
        thumbnail: path + "18g-standard-cannula.jpg",
      },
    ],
    description: [
      "18g x 10cm Multi-Hole Infiltration - 1 nos",
      "12g x 9cm Micro Harvestor - 1 nos",
      "18g x 9cm Micro Side Port Re-Injection - 1 nos",
      "18g x 9cm Micro Open Ended Re-Injection - 1 nos",
      "18g x 9cm Micro V-Dissection Re-Injection - 1 nos",
      "Cleaning Stylets - 4 nos",
      "Female to Female Transfer - 1 nos",
    ],
  },
  {
    title: "Deluxe 16G Micro Cannula Set",
    images: [
      {
        original: path + "16g-deluxe-cannula.jpg",
        thumbnail: path + "16g-deluxe-cannula.jpg",
      },
    ],
    description: [
      "Multi-Hole Infiltration: 16g x 16cm on deluxe luer lock hub - 1 nos",
      "Micro Harvester: 12g x 15cm on deluxe luer lock hub - 1 nos",
      "Micro Side Port Re-Injection: 16g x 9cm on deluxe luer lock hub - 1 nos",
      "Micro Side Port Re-Injection: 16g x 9cm on deluxe luer lock hub, Convex bend - 1 nos",
      "Micro Open Ended Re-Injection: 16g x 9cm on deluxe luer lock hub - 1 nos",
      "Micro Open Ended Re-Injection: 16g x 9cm on deluxe luer lock hub, Convex bend - 1 nos",
      "Micro V-Dissection: 16g x 9cm on deluxe luer lock hub - 1 nos",
      "Cleaning Stylets - 6 nos",
      "Female to Female Transfer - 2 nos",
    ],
  },
  {
    title: "Deluxe 18G Micro Cannula Set",
    images: [
      {
        original: path + "18g-deluxe-cannula.jpg",
        thumbnail: path + "18g-deluxe-cannula.jpg",
      },
    ],
    description: [
      "Multi-Hole Infiltration - 18g x 16cm - 1 nos",
      "Aspiration - 12g x 15cm - 1 nos",
      "Side Port Re-Injection - 18g x 9cm - 1 nos",
      "Convex Side Port Re-Injection - 18g x 9cm - 1 nos",
      "Open Ended Re-Injection - 18g x 9cm - 1 nos",
      "Convex Open Ended Re-Injection - 18g x 9cm - 1 nos",
      "V-Dissection Re-Injection - 18g x 9cm - 1 nos",
      "Cleaning Stylets - 6 nos",
      "Female to Female Transfer - 2 nos",
    ],
  },
  {
    title: "Contoured 16G Micro Cannula Set",
    images: [
      {
        original: path + "16g-contoured.jpg",
        thumbnail: path + "16g-contoured.jpg",
      },
    ],
    description: [
      "16g x 9cm Convex Side Port Re-Injection - 1 nos",
      "16g x 9cm Concave Side Port Re-Injection - 1 nos",
      "16g x 9cm Convex Open Ended Re-Injection - 1 nos",
      "16g x 9cm Concave Open Ended Re-Injection - 1 nos",
      "16g x 9cm Convex V-Dissection Re-Injection - 1 nos",
      "16g x 9cm Concave V-Dissection Re-Injection - 1 nos",
      "Cleaning Stylets - 6 nos",
    ],
  },
  {
    title: "Contoured 18G Micro Cannula Set",
    images: [
      {
        original: path + "18g-contoured.jpg",
        thumbnail: path + "18g-contoured.jpg",
      },
    ],
    description: [
      "16g x 9cm Convex Side Port Re-Injection - 1 nos",
      "16g x 9cm Concave Side Port Re-Injection - 1 nos",
      "16g x 9cm Convex Open Ended Re-Injection - 1 nos",
      "16g x 9cm Concave Open Ended Re-Injection - 1 nos",
      "16g x 9cm Convex V-Dissection Re-Injection - 1 nos",
      "16g x 9cm Concave V-Dissection Re-Injection - 1 nos",
      "Cleaning Stylets - 6 nos ",
    ],
  },
  {
    title: "Dual Port Spatula Micro set",
    images: [
      {
        original: path + "dualPort-spatula-cannula.jpg",
        thumbnail: path + "dualPort-spatula-cannula.jpg",
      },
    ],
    description: [
      "16g x 5cm Dual Port - 1 nos",
      "12g x 15cm Dual Port - 1 nos",
      "14g x 10cm Dual Port - 1 nos",
      "10g x 15cm Dual Port - 1 nos",
      "12g x 10cm Dual Port - 1 nos",
      "14g x 23cm Dual Port - 1 nos",
      "10g x 10cm Dual Port - 1 nos",
      "12g x 23cm Dual Port - 1 nos",
      "16g x 8cm Dual Port - 1 nos",
      "10g x 23cm Dual Port - 1 nos",
      "14g x 15cm Dual Port - 1 nos",
    ],
  },
  {
    title: "Premium Micro Cannula Set",
    images: [
      {
        original: path + "premium-micro.jpg",
        thumbnail: path + "premium-micro.jpg",
      },
    ],
    description: [
      "18g x 16cm Multi-Hole Infiltration - 1 nos",
      "16g x 22cm Multi-Hole Infiltration - 1 nos",
      "14g x 15cm Aspiration - 1 nos",
      "12g x 21cm Aspiration - 1 nos",
      "16g x 9cm Side Port Re-Injection - 1 nos",
      "18g x 9cm Side Port Re-Injection - 1 nos",
      "18g x 9cm Convex Side Port Re-Injection - 1 nos",
      "16g x 9cm Open Ended Re-Injection - 1 nos",
      "18g x 9cm Open Ended Re-Injection - 1 nos",
      "18g x 9cm Convex Open Ended Re-Injection - 1 nos",
      "16g x 9cm Style II Re-Injection - 1 nos",
      "18g x 9cm Style II Re-Injection - 1 nos",
      "18g x 9cm V-Dissection - 1 nos",
    ],
  },
  {
    title: "Mission Micro Cannula Set",
    images: [
      {
        original: path + "mission-cannula.jpg",
        thumbnail: path + "mission-cannula.jpg",
      },
    ],
    description: [
      "14g x 10cm Dual Port Spatula - 1 nos ",
      "14g x 15cm Dual Port Spatula - 1 nos ",
      "12g x 10cm Dual Port Spatula - 1 nos ",
      "12g x 15cm Dual Port Spatula - 1 nos ",
      "16g x 8cm Puente Tornado - 1 nos ",
      "16g x 12cm Puente Tornado - 1 nos ",
      "14g x 15cm Puente Tornado - 1 nos ",
      "14g x 23cm Puente Tornado - 1 nos ",
      "12g x 15cm Puente Tornado - 1 nos ",
      "12g x 23cm Puente Tornado - 1 nos ",
      "Cleaning Stylets - 10 nos ",
    ],
  },

  {
    title: "Cannula Standard 3 - 1.6mm x 12cm",
    images: [
      {
        original: path + "standard-1.6x12/original.jpg",
        thumbnail: path + "standard-1.6x12/thumbnail.jpg",
      },
    ],

    description: [
      "Standard Quick Screw style Liposuction Cannula, two piece, 1.6 millimeter by 12 centimeter",
      "Liposuction Cannula",
      "Quick Screw",
      "1.6 mm",
      "12 cm",
    ],
  },

  {
    title: "Cannula Standard 3 - 1.6mm x 15cm",
    images: [
      {
        original: path + "standard-1.6x15/original.jpg",
        thumbnail: path + "standard-1.6x15/thumbnail.jpg",
      },
    ],

    description: ["Liposuction Cannula", "Quick Screw", "1.6 mm", "15 cm"],
  },

  {
    title: "Cannula Standard 3 - 2mm x 15cm",
    images: [
      {
        original: path + "standard-2x15/original.jpg",
        thumbnail: path + "standard-2x15/thumbnail.jpg",
      },
    ],

    description: ["Liposuction Cannula", "Quick Screw", "2 mm", "15 cm"],
  },
  {
    title: "Cannula  4.6mm x 30cm",
    images: [
      {
        original: path + "lv-4.6x30/original.jpg",
        thumbnail: path + "lv-4.6x30/thumbnail.jpg",
      },
    ],

    description: [
      "Las Vegas Liposuction Cannula One piece. 4.6 millimeters x 30 centimeters.",
    ],
  },

  {
    title: "Cannula  5.2mm x 30cm",
    images: [
      {
        original: path + "lv-5.2x30/original.jpg",
        thumbnail: path + "lv-5.2x30/thumbnail.jpg",
      },
    ],

    description: [
      "Las Vegas Liposuction Cannula One piece. 5.2 millimeters x 30 centimeters.",
    ],
  },

  {
    title: "Cannula Mercedes 1.6mm x 12cm",
    images: [
      {
        original: path + "mercedes-1.6x12/original.jpg",
        thumbnail: path + "mercedes-1.6x12/thumbnail.jpg",
      },
    ],

    description: [
      "Mercedes Liposuction Cannula with small handle and swivel barb. One piece. 1.6 millimeters x 12 centimeters.",
    ],
  },

  {
    title: "Cannula Mercedes 3mm x 32cm",
    images: [
      {
        original: path + "mercedes-3x32/original.jpg",
        thumbnail: path + "mercedes-3x32/thumbnail.jpg",
      },
    ],

    description: [
      "Mercedes Liposuction Cannula. 3 millimeters. 32 centimeters long.",
    ],
  },

  {
    title: "Cannula Mercedes 3mm x 38cm",
    images: [
      {
        original: path + "mercedes-3x38/original.jpg",
        thumbnail: path + "mercedes-3x38/thumbnail.jpg",
      },
    ],

    description: [
      "Mercedes Liposuction Cannula. 3 millimeters. 38 centimeters long.",
    ],
  },

  {
    title: "Cannula Mercedes 4mm x 32cm",
    images: [
      {
        original: path + "mercedes-4x32/original.jpg",
        thumbnail: path + "mercedes-4x32/thumbnail.jpg",
      },
    ],

    description: [
      "Mercedes Liposuction Cannula. 4 millimeters. 32 centimeters long.",
    ],
  },
  {
    title: "Cannula Mercedes 2mm x 12cm",
    images: [
      {
        original: path + "mercedes-2x12/original.jpg",
        thumbnail: path + "mercedes-2x12/thumbnail.jpg",
      },
    ],

    description: [
      "Mercedes Liposuction Cannula with small handle and swivel barb. One piece. 2 millimeters x 12 centimeters.",
    ],
  },

  {
    title: "Cannula 3mm x 21cm Concave",
    images: [
      {
        original: path + "standard-3x21/original.jpg",
        thumbnail: path + "standard-3x21/thumbnail.jpg",
      },
    ],

    description: [
      "Standard Liposuction Cannula with port on the inside of the curve and a small handle with a swivel barb. One piece. 3 millimeters x 21 centimeters. Concave.",
    ],
  },

  {
    title: "Garden Spray Infiltration Needle 12gx15cm LL",
    images: [
      {
        original: path + "garden-12x15/original.jpg",
        thumbnail: path + "garden-12x15/thumbnail.jpg",
      },
    ],

    description: [
      "Garden Spray Infiltration Liposuction Cannula, 12 gauge, with luer lock. 15 centimeters long.",
    ],
  },

  {
    title: "Garden Spray Infiltration Needle 14g x 15cm LL",
    images: [
      {
        original: path + "garden-14x15/original.jpg",
        thumbnail: path + "garden-14x15/thumbnail.jpg",
      },
    ],

    description: [
      "Garden Spray Infiltration Liposuction Cannula, 14 gauge, with luer lock. 15 centimeters long.",
    ],
  },

  {
    title: "Garden Spray Infiltration Needle 14g x 25cm LL",
    images: [
      {
        original: path + "garden-14x25/original.jpg",
        thumbnail: path + "garden-14x25/thumbnail.jpg",
      },
    ],

    description: [
      "Garden Spray Infiltration Liposuction Cannula, 14 gauge, with luer lock. 25 centimeters long.",
    ],
  },

  {
    title: "Garden Spray Infiltration Needle 14g x 30cm LL",
    images: [
      {
        original: path + "garden-14x30/original.jpg",
        thumbnail: path + "garden-14x30/thumbnail.jpg",
      },
    ],

    description: [
      "Garden Spray Infiltration Liposuction Cannula, 14 gauge, with luer lock. 30 centimeters long.",
    ],
  },

  {
    title: "Garden Spray Infiltration Needle 12g x 30cm LL",
    images: [
      {
        original: path + "garden-12x30/original.jpg",
        thumbnail: path + "garden-12x30/thumbnail.jpg",
      },
    ],

    description: [
      "Garden Spray Infiltration Liposuction Cannula, 12 gauge, with luer lock. 30 centimeters long.",
    ],
  },

  {
    title: "Infiltration Cannula 12g 23cm LL",
    images: [
      {
        original: path + "infiltration-12x23/original.jpg",
        thumbnail: path + "infiltration-12x23/thumbnail.jpg",
      },
    ],

    description: [
      "Infiltration Liposuction Cannula, 12 gauge, with luer lock. 23 centimeters long.",
    ],
  },

  {
    title: "Infiltration Needle  16g x 20cm",
    images: [
      {
        original: path + "infiltration-16x20/original.jpg",
        thumbnail: path + "infiltration-16x20/thumbnail.jpg",
      },
    ],

    description: [
      "Infiltration Liposuction Needle, 16 gauge. 20 centimeters long.",
    ],
  },

  {
    title: "Infiltration Needle  16g x 20cm",
    images: [
      {
        original: path + "infiltration-16x20/original.jpg",
        thumbnail: path + "infiltration-16x20/thumbnail.jpg",
      },
    ],

    description: [
      "Infiltration Liposuction Needle, 16 gauge. 20 centimeters long.",
    ],
  },
];
