const path =
  process.env.PUBLIC_URL + "./images/products/medicalEquipments/microDerma/";

export const microDermaList = [
  {
    title: "2900MD",
    images: [
      {
        original: path + "md2900/original.jpg",
        thumbnail: path + "md2900/thumbnail.jpg",
      },
    ],

    description: [
      "Remarkably Quiet!",
      "Adjustable Crystal flow control",
      "Adjustable Vacuum control",
      "Low Maintenance",
      "Low cost per treatment",
    ],
  },

  {
    title: "Portapeel Pro",
    images: [
      {
        original: path + "portapeelPro/original.jpg",
        thumbnail: path + "portapeelPro/thumbnail.jpg",
      },
    ],

    description: [
      "Lightweight and Compact",
      "Portable with carrying handle",
      "Remarkably quiet system",
      "Adjustable Crystal flow control",
      "Adjustable Vacuum control",
      "Ergonomically designed patented Handpieces",
      "Low cost per treatment",
    ],
  },
  {
    title: "Dimo Dermabrasion",
    images: [
      {
        original: path + "dimoDerma/original.jpg",
        thumbnail: path + "dimoDerma/thumbnail.jpg",
      },
    ],

    description: [
      "Includes 2 lightweight metal handpieces",
      "9 assorted diamond embeded tips ranging in size(diameter) and grit(fine, medium, coarse)",
      "tubing in a custom - made acrylic case.",
    ],
  },
];
