const path =
  process.env.PUBLIC_URL + "./images/products/accessories/collectionCanister/";

export const collectionCanistersList = [
  {
    title: "Fat Collection Canister 100cc",
    images: [
      {
        original: path + "100cc/original.jpg",
        thumbnail: path + "100cc/thumbnail.jpg",
      },
    ],

    description: [],
  },

  {
    title: "Fat Collection Canister 250cc",
    images: [
      {
        original: path + "250cc/original.jpg",
        thumbnail: path + "250cc/thumbnail.jpg",
      },
    ],

    description: [],
  },
  {
    title: "Fat Collection Canister 500cc",
    images: [
      {
        original: path + "500cc/original.jpg",
        thumbnail: path + "500cc/thumbnail.jpg",
      },
    ],

    description: [],
  },
  {
    title: "Fat Collection Canister 1000cc",
    images: [
      {
        original: path + "1000cc/original.jpg",
        thumbnail: path + "1000cc/thumbnail.jpg",
      },
    ],

    description: [],
  },

  {
    title: "Fat Collection Canister 1500cc",
    images: [
      {
        original: path + "1500cc/original.jpg",
        thumbnail: path + "1500cc/thumbnail.jpg",
      },
    ],

    description: [],
  },

  {
    title: "Fat Collection Canister 2000cc",
    images: [
      {
        original: path + "2000cc/original.jpg",
        thumbnail: path + "2000cc/thumbnail.jpg",
      },
    ],

    description: [],
  },
];
