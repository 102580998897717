import React from "react";
const About = () => (
  <section id="about" className="about">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>About Us</h2>
        <p>
          Understanding the toil and labour of manual handling of equipments, we
          present to you ‘ALTIUS’.
        </p>
      </div>

      <div className="row">
        <div className="col-lg-6" data-aos="fade-right">
          <img src="assets/img/about.jpg" className="img-fluid" alt="" />
        </div>
        <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left">
          <h3> Who are we?</h3>
          <h6>A source of reliable equipments</h6>
          <p className="font-italic">
            We make the medical world easy and comfortable. We customize
            facilities both for the buyer and the user. With the progressing
            technologies, Altius is here to bring a change in medical equipments
            too. Monitoring a child might be fun! But what about the machines
            that needs utmost care and attention? Yes! We provide you with
            highly computerized equipments, that makes our life quite
            undemanding. A change is something which we all wish for. Altius is
            here with a chain of solution as to the medical dilemma.
          </p>
          <ul>
            <li>
              <i className="icofont-check-circled"></i> We give prime importance
              to the welfare of human lives because we believe that human life
              is worthy and valuable.
            </li>
            <li>
              <i className="icofont-check-circled"></i> We ensure safety and
              quality in the best interest of the organization concerned.
            </li>
            <li>
              <i className="icofont-check-circled"></i> We are different and our
              intention is to bring about a change in this world.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);
export default About;
