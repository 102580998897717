const path =
  process.env.PUBLIC_URL + "./images/products/medicalEquipments/aspirators/";

export const aspiratorList = [
  {
    title: "HERCULES™",
    images: [
      {
        original: path + "original.png",
        thumbnail: path + "thumbnail.png",
      },
    ],

    description: [
      "Most Powerful aspirator available",
      "Dent-free, scratch resistant casing",
      "Superior quality inside and out",
      "5 year warranty on parts and labor ",
    ],
  },
];
