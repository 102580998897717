const path =
  process.env.PUBLIC_URL +
  "./images/products/medicalEquipments/imagingSystems/";

export const imagingSystemList = [
  {
    title: "CARDIO E3",
    images: [
      {
        original: path + "ecg/original.png",
        thumbnail: path + "ecg/thumbnail.png",
      },
    ],

    description: [
      "3-channel ECG with 7-inch LCD color screen",
      "12 simultaneous derivatives including ECG interpretation program",
      "Sampling frequency 1,000 Hz",
      "Pacemaker detection 10,000 Hz",
      "Mains filter for suppressing 50 / 60 Hz interference",
      "Registration speed 5/10/25/50 mm/s (man. print)",
      "Sensitivity adjustable: 2.5/5/10/20 mm/mV automatic operation or manual",
      "External USB printer with PCL6 language",
    ],
  },

  {
    title: `PROview 10" patient monitor`,
    images: [
      {
        original: path + "patientMonitoring/original.png",
        thumbnail: path + "patientMonitoring/thumbnail.png",
      },
    ],

    description: [
      "Brilliant image display",
      "27 different types of arrhythmia analysis",
      "Early Warning Score (EMS) - Assessing the urgency of medical action",
      "Glasgow Coma Scale (GCS)",
      "Monitoring of the respiratory tract in neonates",
      "Measurement of oxygen saturation (SpO2) and pulse rate",
      "Easy hygienic cleaning",
      "Uncomplicated storage of the accessories on the back of the device",
      "Blood pressure measurement for adults and children (NIBP)",
      "Central monitoring software for up to 66 monitors (optional)",
      "4 hours battery life / up to 8 hours (optional) expandable",
    ],
  },
];
