import { disposablesList } from "./categories/disposables";
import { syringeList } from "./categories/syringes";
import { collectionCanistersList } from "./categories/collectionCanisters";
import { canisterSystemList } from "./categories/canisterSystem";
import { syringeRackList } from "./categories/syringeRacks";
import { pressureCuffsList } from "./categories/pressureCuffs";

export const accessoryList = [
  {
    key: "disposables",
    title: "Disposables",
    list: disposablesList,
  },
  {
    key: "canisters",
    title: "Collection Canisters",
    list: collectionCanistersList,
  },
  {
    key: "syringes",
    title: "Syringes",
    list: syringeList,
  },
  {
    key: "pressureCuffs",
    title: "Pressure Cuffs",
    list: pressureCuffsList,
  },
  {
    key: "syringesRacks",
    title: "Syringe Racks",
    list: syringeRackList,
  },
  {
    key: "canistersSystem",
    title: "Canister Systems",
    list: canisterSystemList,
  },
];
