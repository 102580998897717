import React from "react";
import { NavLink } from "react-router-dom";
const NavBar = () => (
  <header id="header" className="fixed-top">
    <div className="container d-flex align-items-center">
      <h1 className="logo mr-auto">
        <NavLink to="/">ALTIUS MEDICAL EQUIPMENTS</NavLink>
      </h1>
      <nav className="nav-menu d-none d-lg-block">
        <ul>
          <li>
            <NavLink exact to="/" activeStyle={{ color: "#07689f" }}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink activeStyle={{ color: "#07689f" }} exact to="/about">
              About
            </NavLink>
          </li>
          <li>
            <NavLink activeStyle={{ color: "#07689f" }} exact to="/products">
              Products
            </NavLink>
          </li>

          <li>
            <NavLink activeStyle={{ color: "#07689f" }} exact to="/contact">
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  </header>
);
export default NavBar;
