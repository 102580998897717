import { centrifugeList } from "./categories/centrifuge";
import { refrigeratorList } from "./categories/refridgerator";

export const scientificEquipmentLists = [
  {
    key: "refrigerators",
    title: "Refrigerators",
    list: refrigeratorList,
  },
  {
    key: "centrifuge",
    title: "Centrifuge",
    list: centrifugeList,
  },
];
