import React, { useState } from "react";
import Container from "../../components/Container";
import { accessoryList } from "../../components/json/accessories/accessoriesList";
import ProductSubCategories from "./ProductSubCategories";

import TabContent from "./TabContent";

const Accessories = () => {
  const [productDetails, setProductDetails] = useState(accessoryList[0]);
  const setProductDetailsHandler = (details) => setProductDetails(details);

  return (
    <Container>
      <div className="section-title">
        <h2>Accessories</h2>
        <p>
          The Altius section of accessories provides you with syringes, cannulas
          canisters and all the other associated items. We consider these as the
          very essential elements required for any medical purposes. These are
          extremely important and care needs to be given as some of these come
          in direct contact with the inner parts of the human body. Due to this
          we carry out different stages of sterilization ensuring clean and safe
          Altius accessories.
        </p>
      </div>
      <ProductSubCategories
        list={accessoryList}
        setProductDetailsHandler={setProductDetailsHandler}
      />
      {productDetails && <TabContent {...productDetails} />}
    </Container>
  );
};
export default Accessories;
