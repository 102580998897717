const path =
  process.env.PUBLIC_URL + "./images/products/accessories/canisterSystem/";

export const canisterSystemList = [
  {
    title: "Collection Canister Stopper",
    images: [
      {
        original: path + "canisterStopper/original.jpg",
        thumbnail: path + "canisterStopper/thumbnail.jpg",
      },
    ],

    description: ["Class VI Medical grade silicone "],
  },

  {
    title: "Collection Holder",
    images: [
      {
        original: path + "canisterHolder/original.jpg",
        thumbnail: path + "canisterHolder/thumbnail.jpg",
      },
    ],

    description: ["Description Not available"],
  },

  {
    title: "RECEPTAL® 1000ml Canister and Liners",
    images: [
      {
        original: path + "receptal1000/original.jpg",
        thumbnail: path + "receptal1000/thumbnail.jpg",
      },
    ],

    description: [
      "Easy to read volume marks",
      "Large patient label strip for convenient documentation",
      "Canister “T” designates vacuum source connection to simplify canister set up",
      "Non-Sterile",
      " Reusable",
      "Latex-Free and DEHP Free",
    ],
  },

  {
    title: "RECEPTAL® 2000ml Canister and Liners",
    images: [
      {
        original: path + "receptal2000/original.jpg",
        thumbnail: path + "receptal2000/thumbnail.jpg",
      },
    ],

    description: [
      "Easy to read volume marks",
      "Large patient label strip for convenient documentation",
      "Canister “T” designates vacuum source connection to simplify canister set up",
      "Non-Sterile",
      " Reusable",
      "Latex-Free and DEHP Free",
    ],
  },

  {
    title: "RECEPTAL® 3000ml Canister and Liners",
    images: [
      {
        original: path + "receptal3000/original.jpg",
        thumbnail: path + "receptal3000/thumbnail.jpg",
      },
    ],

    description: [
      "Easy to read volume marks",
      "Large patient label strip for convenient documentation",
      "Canister “T” designates vacuum source connection to simplify canister set up",
      "Non-Sterile",
      " Reusable",
      "Latex-Free and DEHP Free",
    ],
  },

  {
    title: "Baxter 1500ml & 3000ml Canister & Liner Collection",
    images: [
      {
        original: path + "baxter15003000/original.jpg",
        thumbnail: path + "baxter15003000/thumbnail.jpg",
      },
    ],

    description: [
      "Hard outer canister with an internal flexible liner",
      "Implosion proof system",
    ],
  },
  {
    title: "Abbott 2000ml Canister & Liner Collection System",
    images: [
      {
        original: path + "abbott2000/original.jpg",
        thumbnail: path + "abbott2000/thumbnail.jpg",
      },
    ],

    description: ["Description Not Available"],
  },

  {
    title: "Plastic male barb for Abbott Canister",
    images: [
      {
        original: path + "canisterBarb/original.jpg",
        thumbnail: path + "canisterBarb/thumbnail.jpg",
      },
    ],

    description: ["Description Not Available"],
  },

  {
    title: "Replacement 'T'",
    images: [
      {
        original: path + "replacementT/original.jpg",
        thumbnail: path + "replacementT/thumbnail.jpg",
      },
    ],

    description: [
      "Stainless steel",
      "Available for your Abbott 2000CC Canister system",
      "Designed to  reduce canister breakage",
    ],
  },

  {
    title: "Baxter 3000ml Canister Collection system",
    images: [
      {
        original: path + "baxter3000/original.jpeg",
        thumbnail: path + "baxter3000/thumbnail.jpeg",
      },
    ],

    description: ["Disposable canister"],
  },

  {
    title: "Autoclave Compatible Collection System",
    images: [
      {
        original: path + "autoclave/original.jpg",
        thumbnail: path + "autoclave/thumbnail.jpg",
      },
    ],

    description: ["Description Not Available"],
  },

  {
    title: "Collection Canister Caps",
    images: [
      {
        original: path + "canisterCaps/original.jpg",
        thumbnail: path + "canisterCaps/thumbnail.jpg",
      },
    ],

    description: ["Screw on canister cap", "Black delrin"],
  },
];
