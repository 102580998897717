import React, { useState } from "react";
import Container from "../../components/Container";
import { medicalEquipmentLists } from "../../components/json/medicalEquipments/medicalEquipmentList";
import ProductSubCategories from "./ProductSubCategories";

import TabContent from "./TabContent";

const MedicalEquipments = () => {
  const [productDetails, setProductDetails] = useState(
    medicalEquipmentLists[0]
  );
  const setProductDetailsHandler = (details) => setProductDetails(details);

  return (
    <Container>
      <div className="section-title">
        <h2>Medical Equipments</h2>
        <p>
          Medical equipments are very essential for the diagnosis and /treatment
          of diseases or injuries. Now a days getting the images of inner body
          parts or internal organs requires modern and highly sophisticated
          instruments. Altius supplies a wide variety of medical equipments
          required for this purpose. We also bring forth to you a versatile
          range of options making life comfortable.
        </p>
      </div>
      <ProductSubCategories
        list={medicalEquipmentLists}
        setProductDetailsHandler={setProductDetailsHandler}
      />
      {productDetails && <TabContent {...productDetails} />}
    </Container>
  );
};
export default MedicalEquipments;
